/*eslint-disable*/
import React from "react";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & content 
import getCulture from "../../../assets/utils/getCulture";
import content from "./content";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Projects() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <div className="cd-section" id="projects">
        <div className="projects-3" data-background-color="black">
          <Container>
            {/* <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h6 className="category text-muted">Our work</h6>
                <h2 className="title">Some of Our Awesome Projects - 3</h2>
              </Col>
            </Row> */}
            {/* <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/project4.jpg").default +
                        ")",
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "Amber Interiors is available to assist in all aspects of
                      a project from start to finish including consulting with
                      Architects and Builders"
                    </p>
                  </div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/project21.jpg").default +
                        ")",
                    }}
                  ></div>
                  <h6 className="category text-info">Design</h6>
                  <CardTitle tag="h4">Coco Kelley</CardTitle>
                  <p>
                    Amber Lewis’s interior design blog takes you inside the
                    creative workings of her Los Angeles–based studio. Keep an
                    eye on this website for an industry insider’s point of view.
                  </p>
                </Col>
              </Row>
            </div> */}
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left rellax"
                    data-rellax-speed="3"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/GGW/holding.jpg").default +
                        ")",
                    }}
                  >
                    <p className={`blockquote blockquote-info ${culture==='ar' ? "arForm" : ""}`}>
                    {content.ggwDiscription[culture]}
                    </p>
                  </div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right rellax"
                    data-rellax-speed="2"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/GGW/woman-laptop.jpg").default +
                        ")",
                    }}
                  ></div>
                  {/* <h6 className="category text-info">E-Decor</h6> */}
                  {/* <CardTitle className={culture==='ar' ? "arForm" : ""} tag="h4"></CardTitle> */}
                  <div className={culture==='ar' ? "arForm" : ""} >
                <h2>{content.title[culture]}</h2>
                <h3 style={{marginTop: "-25px", color: "#969797"}}>{content.subTitle[culture]}</h3>
              </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Projects;
