/*!

=========================================================
* Now UI Kit PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Redirect, Switch } from "react-router-dom";

// get culture from utils 
import getCulture from "./assets/utils/getCulture";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "./assets/css/css.css";
// pages
import AboutUs from "views/examples/AboutUs/AboutUs.js";
// import BlogPost from "views/examples/BlogPost.js";
// import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs/ContactUs";
// import Ecommerce from "views/examples/Ecommerce.js";
// import Index from "views/Index.js";
// import LandingPage from "views/examples/LandingPage.js";
// import LoginPage from "views/examples/LoginPage.js";
// import NucleoIcons from "views/NucleoIcons.js";
import Presentation from "views/Presentation.js";
// import Pricing from "views/examples/Pricing.js";
// import ProductPage from "views/examples/ProductPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import Sections from "views/Sections.js";
// import SignupPage from "views/examples/SignupPage.js";
// others

// let rout = useRouteMatch()
let culture = getCulture(window.location.pathname);
console.log(window.location.pathname);

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path={`/${culture}/about-us`} render={(props) => <AboutUs {...props} />} />
      {/* <Route path={`/${culture}/blog-post`} render={(props) => <BlogPost {...props} />} /> */}
      {/* <Route path={`/${culture}/blog-posts`} render={(props) => <BlogPosts {...props} />} /> */}
      <Route path= {`/${culture}/contact-us`} render={(props) => <ContactUs {...props} />} />
      {/* <Route path={`/${culture}/e-commerce`} render={(props) => <Ecommerce {...props} />} /> */}
      {/* <Route path={`/${culture}/index`} render={(props) => <Index {...props} />} /> */}
      {/* <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      /> */}
      {/* <Route path="/login-page" render={(props) => <LoginPage {...props} />} /> */}
      {/* <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      /> */}
      <Route
        path={`/${culture}/home`}
        render={(props) => <Presentation {...props} />}
      />
      {/* <Route path="/pricing" render={(props) => <Pricing {...props} />} /> */}
      {/* <Route
        path="/product-page"
        render={(props) => <ProductPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      /> */}
      {/* <Route path={`/${culture}/sections`} render={(props) => <Sections {...props} />} /> */}
      {/* <Route path="/sign-up" render={(props) => <SignupPage {...props} />} /> */}
      <Redirect to={`/${culture}/home`} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
