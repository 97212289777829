import React from "react";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & content 
import getCulture from "../../../assets/utils/getCulture";
import content from "./content";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function Features() {

   //  Changing Language
   let rout = useRouteMatch()
   let culture = getCulture(rout.url);

  return (
    <>
      <div className="cd-section" id="features">
        <div
          className="features-2 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/GGW/male.jpg").default + ")",
          }}
        >
          <Container fluid>
            <Row>
              <Col className="mr-auto ml-auto" md="8">
                <h2 className="title">{content.title2[culture]}</h2>
                <h4 className="description">{content.work[culture]}</h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info icon-circle">
                    <i className="now-ui-icons users_single-02 gold"></i>
                  </div>
                  <h4 className="info-title">{content.team[culture]}</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger icon-circle">
                    <i className="now-ui-icons business_chart-bar-32 gold"></i>
                  </div>
                  <h4 className="info-title">{content.paltform[culture]}</h4>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-warning icon-circle">
                    <i className="now-ui-icons arrows-1_refresh-69 gold"></i>
                  </div>
                  <h4 className="info-title">{content.sync[culture]}</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Features;
