import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import {
    useRouteMatch,
  } from "react-router-dom";
  
  // get culture & contents 
  import getCulture from "../../assets/utils/getCulture";
  import contents from "./contents";

// core components

function Components() {
    
  //  Changing Language
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  return (
    <>
      <div
        className="section section-components"
        data-background-color="black"
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center title">
                ERP <br></br>
                <small className="description">
                {contents.erp[culture]}
                </small>
              </h2>
              <h5 className="text-center description">
                {contents.erpDiscription[culture]}<br></br>...
              </h5>
              <div className="space-50"></div>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <div className="card-container first-card">
                <div className="card-component">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="front">
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/erp1.jpg")
                            .default
                        }
                      ></img>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container second-card">
                <div className="card-component">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="front">
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/erp2.jpg")
                            .default
                        }
                      ></img>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container third-card">
                <div className="card-component">
                  <a href="sections.html">
                    <div className="front">
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/erp3.jpg")
                            .default
                        }
                      ></img>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container fourth-card">
                <div className="card-component">
                  <a href="examples/product-page.html">
                    <div className="front">
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/erp4.jpg")
                            .default
                        }
                      ></img>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Components;
