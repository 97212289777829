import React from "react";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & contents 
import getCulture from "../../assets/utils/getCulture";
import contents from "./contents";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function BasicComponents() {

    //  Changing Language
    let rout = useRouteMatch()
    let culture = getCulture(rout.url);

  return (
    <>
      <div className="section section-basic-components">
        <Container>
          <Row>
            <Col className={`ml-auto mr-auto ${culture==='ar'?'arForm':''}`} lg="5" md="7">
              <h2 className="title">{contents.erp[culture]}</h2>
              <h6 className="category">{contents.erpImp[culture]}</h6>
              <h5 className="description">
                {contents.erp1[culture]}
              </h5>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="components-macbook"
                  src={require("assets/img/ipad-ggw.png").default}
                ></img>
                <img
                  alt="..."
                  className="table-img"
                  src={
                    require("assets/img/presentation-page/table - GGW.png").default
                  }
                ></img>
                <img
                  alt="..."
                  className="share-btn-img"
                  src={
                    require("assets/img/presentation-page/share-btn - Copy.png")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={
                    require("assets/img/presentation-page/coloured-card-with-btn - GGW.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="coloured-card-img"
                  src={
                    require("assets/img/presentation-page/GGW1.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="social-img"
                  src={
                    require("assets/img/presentation-page/orders.jpg")
                      .default
                  }
                ></img>
                {/* <img
                  alt="..."
                  className="linkedin-btn-img"
                  src={
                    require("assets/img/presentation-page/linkedin-btn.jpg")
                      .default
                  }
                ></img> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default BasicComponents;
