export default {

    services: {
        ar: "خدماتنا",
        en: "Services"
    },
    servicesSub: {
        ar: "إليكم مجموعة من الخدمات التي نقدمها",
        en: "Listed below are some of our services"
    },
    software: {
        ar: "تطوير البرمجيات",
        en: "SOFTWARE DEVELOPMENT"
    },
    network: {
        ar: "تطوير الشبكات",
        en: "NETWORK DEVELOPMENT"
    },
    mobileApp: {
        ar: "تطوير تطبيقات الهاتف",
        en: "MOBILE APP DEVELOPMENT"
    },
    techCons: {
        ar: "إستشارات تقنية",
        en: "TECHNOLOGY CONSULTANCY"
    },
    aboutUsTitle: {
        ar: "عنَّا",
        en: "About Us"
    },
    aboutUsDiscription: {
        ar: " المنفذ الذهبي، شركة مختصة بتقديم حلول تقنية فريدة تلبي احتياجات المستخدم بجودة وكفاءة تحاكي المعايير العالمية، تأسست منذ 2010م، بكادر وظيفي لأكثر من 20 موظف من أصحاب المؤهلات العليا وذوي خبرات بشركات عالمية",
        en: "Golden Gateway is a company specialized in technical ideas and software solutions that meet customer needs with high quality and efficiency"
    },
    more: {
        ar: "... المزيد",
        en: "more ..."
    },
    impressive: {
        ar: "مجموعة مميزة من الأنظمة",
        en: "Impressive collection of systems"
    },
    erp: {
        ar: "نظام إدارة موارد المؤسسة",
        en: "Enterprise Resource Planing"
    },
    erpDiscription: {
        ar: "نظام إداري إلكتروني يشير إلى تخطيط إدارة المؤسسات من خلال برامج تستعين بها المؤسسات لإدارة أنشطة أعمالها اليومية كـالمحاسبة، المشتريات، إدارة المشروعات، إدارة المخاطر والإمتثال، عمليات سلسلة التوريد، الموارد البشرية، بالإضافة إلى المخزون وأوامر الشحن والخدمات",
        en: "An electronic administrative system that refers to the planning of enterprise management through “programs” that enterprises use to manage their daily business activities such as accounting, procurement, project management, risk management and compliance, supply chain operations, human resources, in addition to inventory, shipping orders, services, etc."
    },
    erpImp: {
        ar: ": يعتبر وجود هذا النظام مهما في مؤسستك أو شركتك في أنه",
        en: "The presence of this system is important in your organization or company in that it:"
    },
    erp1: {
        ar: "يمنح الإدارة فرصة الإدارة المركزية في أداء واجبها بشكل يتمتع بالاستقلالية .يبسّط العمليات الإدارية ويمنحها الشكل الأوتوماتيكي ، والذي بدوره سيزيد من الإنتاج الإجمالي للمؤسسة والموظفين . يساعد النظام بشكل مباشر في إدارة أفضل للشؤون المالية والضرائب ، والتي ستوفر الكثير من الأموال للمؤسسة . و أيضاً يزيد من توافق جميع أقسام الشركة ، مما يزيد استقرارها . وهو يقدّم تقارير وافية وتحليلات توفّر الكثير من الجهد في اتخاذ القرارات ، وبالتالي قيادة المؤسسة بذكاء أكبر",
        en: "It gives the administration the opportunity for the central administration to perform its duty in an independent manner, therefore it simplifies administrative processes and gives them an automated form, which in turn will increase the overall production of the organization and employees."
    },
    ourClients: {
        ar: "عملائنا",
        en: "Our Clients"
    },
    clients: {
        ar: "يأتي عملاؤنا أولاً ، ويسعدنا العمل مع هذه الشركات",
        en: "Our clients come first, and We are pleasured to be working with such companies"
    },
    maya: {
        ar: "M.A.Y.A",
        en: "M.A.Y.A"
    },
    mayaD: {
        ar: "الوكالة المغاربية لمناشط الشباب",
        en: "Maghreb Agency for Youth Activities"
    },
    shuaa: {
        ar: "شعاع",
        en: "Shuaa"
    },
    shuaaD: {
        ar: "شعاع ليبيا للخدمات النفطية",
        en: "Shuaa Libya for Oil services"
    },
    urban: {
        ar: "العمران",
        en: "Urban"
    },
    urbanD: { 
        ar: "معرض ومنتدى العمران", 
        en: "The Urban Expo and Forum"
    },
    demo: {
        ar: "تجريبي ERP نظام",
        en: "Free ERP Demo"
    },
    demoD: {
        ar: "هل ترغب في اختبار ومعرفة مزايا نظام تخطيط موارد المؤسسات قبل شرائه؟ يمكنك تجربة النسخة التجريبية. إنه يتميز بشاشات أساسية كافية لكي تشعر بالتصميم وأيضًا اختبار جودة الكود.",
        en: "Do you want to test and see the benefits of the ERP system before purchasing it? You can give the demo version a try. It features enough basic screens for you to get a feel of the design and also test the quality of the code."
    },
    demoTry: {
        ar: "مشاهدة العرض",
        en: "View Demo"
    },
    

}