import React from "react";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// javascript library that creates a parrallax effect
import Rellax from "rellax";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & content 
import getCulture from "../../../assets/utils/getCulture";
import content from "./content";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";

// core components
import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import Footer from "components/Footers/Footer.js";
// import Content from "./AContent.js";
import WhatIsGGW from "./whatIsGGW"
import WorkProcess from "./workProcess";
import OurWork from "./ourWork";
import ERP from "./ERP";
import OurTeam from "./OurTeam";

function AboutUs() {

  //  Changing Language
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  //  UseState
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  //   Show more 1
  const myFunction = () => {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = content.more[culture];
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = content.less[culture];
      moreText.style.display = "inline";
    }
  }

  //   Show more 2
  const myFunction2 = () => {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = content.more[culture];
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = content.less[culture];
      moreText.style.display = "inline";
    }
  }

  //  Collapse 
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  //  useEffect
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  
  return (
    <>
      <DropdownFixedNavbar />
      <div className="wrapper">
        <AboutUsHeader />
        {/* <Content /> */}
        <WhatIsGGW />
        {/* <div className="section">
        <div className="text-center middlePadding">
        <h1 className="title gold">{content.faq[culture]}</h1>
        <div
        aria-multiselectable={false}
        className="card-collapse"
        id="accordion"
        role="tablist"
      >
        <Card className="card-plain">
          <CardHeader id="headingOne" role="tab">
            <a
              className="qSize"
              aria-expanded={collapses.includes(1)}
              data-toggle="collapse"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                changeCollapse(1);
              }}
            >
              {content.Q1[culture]}{" "}
              <i className="now-ui-icons arrows-1_minimal-down gold"></i>
            </a>
          </CardHeader>
          <Collapse isOpen={collapses.includes(1)}>
          <CardBody className="ansSize">
            {content.A1[culture]}
            </CardBody>
          </Collapse>
        </Card>
        <Card className="card-plain">
          <CardHeader id="headingTwo" role="tab">
            <a
              className="qSize"
              aria-expanded={collapses.includes(2)}
              data-toggle="collapse"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                changeCollapse(2);
              }}
            >
              {content.Q2[culture]}{" "}
              <i className="now-ui-icons arrows-1_minimal-down gold"></i>
            </a>
          </CardHeader>
          <Collapse isOpen={collapses.includes(2)}>
          <CardBody className="ansSize">
            {content.A2[culture]}
            </CardBody>
          </Collapse>
        </Card>
        <Card className="card-plain">
          <CardHeader id="headingThree" role="tab">
            <a
              className="qSize"
              aria-expanded={collapses.includes(3)}
              data-toggle="collapse"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                changeCollapse(3);
              }}
            >
              {content.Q3[culture]}{" "}
              <i className="now-ui-icons arrows-1_minimal-down gold"></i>
            </a>
          </CardHeader>
          <Collapse isOpen={collapses.includes(3)}>
            <CardBody className="ansSize">
            {content.A3[culture]}
            </CardBody>
          </Collapse>
        </Card>
      </div>
      </div>
        </div> */}
        <div className="section about-description text-center">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h1 className="title gold">{content.faq[culture]}</h1>
                    {/* <h4 className="description">
                      The CloudCheckr Cloud Management Platform (CMP) provides
                      full visibility and control to reduce costs, improve
                      cybersecurity posture, and automate critical tasks.
                    </h4> */}
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-circle gold">
                        <i className="now-ui-icons design_bullet-list-67 gold"></i>
                      </div>
                      <h4 className="info-title">{content.Q1[culture]}</h4>
                      <p className="description">
                      {content.A1[culture]}
                      </p>
                      </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-circle gold">
                        <i className="now-ui-icons design_vector gold"></i>
                      </div>
                      <h4 className="info-title">{content.Q2[culture]}</h4>
                      <p className="description">
                      {content.A21[culture]}<span id="dots">...</span><span id="more">{content.A22[culture]}</span>
                      </p>
                      <a className="gold" onClick={() => myFunction()} id="myBtn">{content.more[culture]}</a>
                     </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-circle gold">
                        <i className="now-ui-icons business_bulb-63 gold"></i>
                      </div>
                      <h4 className="info-title no-padd">{content.Q3[culture]}</h4>
                      <p className="description">
                      {content.A31[culture]}<span id="dots2">...</span><span id="more2">{content.A32[culture]}</span>
                      </p>
                      <a className="gold" onClick={() => myFunction2()} id="myBtn2">{content.more[culture]}</a>
                    </div>
                  </Col>
                </Row>
                {/* <Row style={{marginTop: '-7em'}}>
                  <Col md="4">
                    <div className="info info-hover">
                      <p className="description">
                      {content.A1[culture]}
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <p className="description">
                      {content.A21[culture]}<span id="dots">...</span><span id="more">{content.A22[culture]}</span>
                      </p>
                      <a className="gold" href="#pablo" onClick={() => myFunction()} id="myBtn">{content.more[culture]}</a>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <p className="description">
                      {content.A31[culture]}<span id="dots2">...</span><span id="more2">{content.A32[culture]}</span>
                      </p>
                      <a className="gold" href="#pablo" onClick={() => myFunction2()} id="myBtn2">{content.more[culture]}</a>
                    </div>
                  </Col>
                </Row> */}
              </Container>
              </div>
              <div className="section about-description text-center">
              <WorkProcess/>
              </div>
              <div className="section about-description text-center">
              <OurTeam/>
              </div>
              <div className="section about-description text-center">
              <OurWork/>
              </div>
              <div className="section about-description text-center">
              <ERP/>
              </div>
              {/* <div className="section about-description text-center">
          <div className="separator-line bg-info"></div>
          <div className="projects-5">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">We have many advantages</h2>
                  <h4 className="description">
                    This is the paragraph where you can write more details about
                    your projects. Keep you user engaged by providing meaningful
                    information.
                  </h4>
                  <div className="section-space"></div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/project8.jpg").default +
                        ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Social Analytics</CardTitle>
                      <p className="card-description">
                        Insight to help you create, connect, and convert.
                        Understand Your Audience's Interests, Influence,
                        Interactions, and Intent. Discover emerging topics and
                        influencers to reach new audiences.
                      </p>
                      <Badge className="badge-neutral">Analytics</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons ui-2_chat-round"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        {content.Q1[culture]}
                      </h4>
                      <p className="description">
                      {content.A1[culture]}
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons business_chart-pie-36"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">{content.Q2[culture]}</h4>
                      <p className="description">
                      {content.A2[culture]}
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons design-2_ruler-pencil"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">{content.Q3[culture]}</h4>
                      <p className="description">
                      {content.A3[culture]}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col className="ml-auto mt-5" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons users_single-02"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Work With Any Team</h4>
                      <p className="description">
                        Whether it’s for work or even the next family vacation,
                        Trello helps your team.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons business_chart-bar-32"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">A Productivity Platform</h4>
                      <p className="description">
                        Integrate the apps your team already uses directly into
                        your workflow.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons arrows-1_refresh-69"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Always In Sync</h4>
                      <p className="description">
                        No matter where you are, Trello stays in sync across all
                        of your devices.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/project9.jpg").default +
                        ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Trello lets you work.</CardTitle>
                      <p className="card-description text-white">
                        Trello’s boards, lists, and cards enable you to organize
                        and prioritize your projects in a fun, flexible and
                        rewarding way.
                      </p>
                      <Badge className="badge-neutral">Trello</Badge>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="about-team team-4">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">We are nerd rockstars</h2>
                  <h4 className="description">
                    This is the paragraph where you can write more details about
                    your team. Keep you user engaged by providing meaningful
                    information.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/img/olivia.jpg").default}
                            ></img>
                          </a>
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Ella Evelyn</CardTitle>
                          <h6 className="category">Air Crew Member</h6>
                          <p className="card-description">
                            Think in the morning. Act in the noon. Eat in the
                            evening. Sleep in the night......
                          </p>
                          <CardFooter>
                            <Button
                              className="btn-icon btn-neutral"
                              color="twitter"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-twitter"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="facebook"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook-square"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="google"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-google"></i>
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/img/emily.jpg").default}
                            ></img>
                          </a>
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Mila Skylar</CardTitle>
                          <h6 className="category">Architect</h6>
                          <p className="card-description">
                            Love cures people - both the ones who give it and
                            the ones who receive it...
                          </p>
                          <CardFooter>
                            <Button
                              className="btn-icon btn-neutral"
                              color="linkedin"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="facebook"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook-square"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="dribbble"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-dribbble"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="google"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-google"></i>
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/img/james.jpg").default}
                            ></img>
                          </a>
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Daniel Carter</CardTitle>
                          <h6 className="category">Aviation Inspector</h6>
                          <p className="card-description">
                            Keep your face always toward the sunshine - and
                            shadows will fall behind you...
                          </p>
                          <CardFooter>
                            <Button
                              className="btn-icon btn-neutral"
                              color="youtube"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fa fab-youtube"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="twitter"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-twitter"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="instagram"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-instagram"></i>
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/img/michael.jpg").default}
                            ></img>
                          </a>
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Dylan Wyatt</CardTitle>
                          <h6 className="category">Conservation Scientist</h6>
                          <p className="card-description">
                            There is only one corner of the universe you can be
                            certain of improving, and that's your own self...
                          </p>
                          <CardFooter>
                            <Button
                              className="btn-icon btn-neutral"
                              color="linkedin"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="facebook"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook-square"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="google"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-google"></i>
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="about-office">
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">Our office is our second home</h2>
                  <h4 className="description">
                    Here are some pictures from our office. You can see the
                    place looks like a palace and is fully equiped with
                    everything you need to get the job done.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/bg27.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/bg24.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/bg28.jpg").default}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/project6.jpg").default}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/project14.jpg").default}
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="about-contact">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto" md="8">
                  <h2 className="text-center title">Want to work with us?</h2>
                  <h4 className="text-center description">
                    Divide details about your product or agency work into parts.
                    Write a few lines about each one and contact us about any
                    further collaboration. We will get back to you in a couple
                    of hours.
                  </h4>
                  <Form className="contact-form">
                    <Row>
                      <Col md="4">
                        <label>First name</label>
                        <InputGroup
                          className={firstFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="firstname"
                            placeholder="First Name..."
                            type="text"
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="4">
                        <label>Your email</label>
                        <InputGroup
                          className={emailFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="email"
                            placeholder="Email Name..."
                            type="text"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label className="control-label">Speciality</label>
                          <Select
                            className="react-select react-select-primary"
                            onChange={(value) => setSpecialitySelect(value)}
                            classNamePrefix="react-select"
                            placeholder="Speciality"
                            value={specialitySelect}
                            name=""
                            options={[
                              {
                                value: "1",
                                label: "I'm a Designer",
                              },
                              { value: "2", label: "I'm a Developer" },
                              { value: "3", label: "I'm a Hero" },
                            ]}
                          ></Select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="4">
                        <Button
                          className="btn-round mt-4"
                          color="info"
                          size="lg"
                        >
                          Let's talk
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div> */}
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
