import React from "react";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & content 
import getCulture from "../../../assets/utils/getCulture";
import content from "./content";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";


// core components

function Features() {

  //  Changing Language
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  return (
    <>
      <div className="cd-section" id="features">
       
        <div className="features-7 f7">
          <Col className="mr-auto ml-auto text-center" md="8">
            <h2 className="title">{content.ERPtitle[culture]}</h2>
            <h4 className="title mtm gold">
              ERP
            </h4>
          </Col>
          <Container fluid>
            <Row>
              <Col className="px-0" md="6">
                <Col sm="12" style={{paddingTop: culture==='ar'? '6em':'1em'}}>
                  <div className="middlePaddingLess info-horizontal">
                    <div className={`icon gold ${culture==='ar' ? "iar" : ""}`}>
                      <i className="now-ui-icons design_vector"></i>
                    </div>
                    <div className={`description ${culture==='ar' ? "arForm" : ""}`}>
                      <h5 className="info-title">{content.ERPdefT[culture]}</h5>
                      <p className="description">
                      {content.ERPdef[culture]}
                      </p>
                    </div>
                  </div>
                  <div className=" middlePaddingLess info-horizontal">
                    <div className={`icon gold ${culture==='ar' ? "iar" : ""}`}>
                      <i className="now-ui-icons ui-1_settings-gear-63"></i>
                    </div>
                    <div className={`description ${culture==='ar' ? "arForm" : ""}`}>
                      <h5 className="info-title">{content.ERPwpT[culture]}</h5>
                      <p className="description">
                      {content.ERPwp[culture]}
                      </p>
                    </div>
                  </div>
                </Col>
              </Col>
              <Col md="6">
                <div className="image-container">
                  <img
                    alt="..."
                    src={require("assets/img/ipad3-ERP-login.png").default}
                  ></img>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Features;
