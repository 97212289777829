import React from "react";

import {
   useRouteMatch,
} from "react-router-dom";

// get culture & content 
import getCulture from "../../../assets/utils/getCulture";
import content from "./content";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import Footer from "components/Footers/Footer.js";


// const MapWrapper = () => {
//   const mapRef = React.useRef(null);
//   React.useEffect(() => {
//     //  (32.8637297, 13.2329930)
//     let google = window.google;
//     let map = mapRef.current;
//     let lat = "40.748817";
//     let lng = "-73.985428";
//     // let lat = "32.8637297";
//     // let lng = "13.2329930";
//     const myLatlng = new google.maps.LatLng(lat, lng);
//     const mapOptions = {
//       zoom: 13,
//       center: myLatlng,
//       scrollwheel: false,
//       zoomControl: true,
//       styles: [
//         {
//           featureType: "water",
//           elementType: "geometry",
//           stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
//         },
//         {
//           featureType: "landscape",
//           elementType: "geometry",
//           stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
//         },
//         {
//           featureType: "road.highway",
//           elementType: "geometry.fill",
//           stylers: [{ color: "#ffffff" }, { lightness: 17 }],
//         },
//         {
//           featureType: "road.highway",
//           elementType: "geometry.stroke",
//           stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
//         },
//         {
//           featureType: "road.arterial",
//           elementType: "geometry",
//           stylers: [{ color: "#ffffff" }, { lightness: 18 }],
//         },
//         {
//           featureType: "road.local",
//           elementType: "geometry",
//           stylers: [{ color: "#ffffff" }, { lightness: 16 }],
//         },
//         {
//           featureType: "poi",
//           elementType: "geometry",
//           stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
//         },
//         {
//           featureType: "poi.park",
//           elementType: "geometry",
//           stylers: [{ color: "#dedede" }, { lightness: 21 }],
//         },
//         {
//           elementType: "labels.text.stroke",
//           stylers: [
//             { visibility: "on" },
//             { color: "#ffffff" },
//             { lightness: 16 },
//           ],
//         },
//         {
//           elementType: "labels.text.fill",
//           stylers: [
//             { saturation: 36 },
//             { color: "#333333" },
//             { lightness: 40 },
//           ],
//         },
//         { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
//         {
//           featureType: "transit",
//           elementType: "geometry",
//           stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
//         },
//         {
//           featureType: "administrative",
//           elementType: "geometry.fill",
//           stylers: [{ color: "#fefefe" }, { lightness: 20 }],
//         },
//         {
//           featureType: "administrative",
//           elementType: "geometry.stroke",
//           stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
//         },
//       ],
//     };

//     map = new google.maps.Map(map, mapOptions);

//     const marker = new google.maps.Marker({
//       position: myLatlng,
//       map: map,
//       animation: google.maps.Animation.DROP,
//       title: "Now UI Kit PRO React!",
//     });

//     const contentString =
//       '<div class="info-window-content"><h2>Now UI Kit PRO React</h2>' +
//       "<p>A premium Admin for React, Reactstrap, and React Hooks.</p></div>";

//     const infowindow = new google.maps.InfoWindow({
//       content: contentString,
//     });

//     google.maps.event.addListener(marker, "click", function () {
//       infowindow.open(map, marker);
//     });
//   });
//   return (
//     <>
//       <div style={{ height: `100%` }} ref={mapRef}></div>
//     </>
//   );
// };

function ContactUs() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  //  const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.target);
    
  //   fetch('/api/form-submit-url', {
  //     method: 'POST',
  //     body: data,
  //   }); 
  //  }
  return (
    <>
      <DropdownWhiteNavbar />
      <div className="wrapper">
        <ContactUsHeader />
        <div className="main map-top">
          <div className="contact-content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                <div className={culture==='ar' ? "arForm" : ""}>
                  <h2 className="title">{content.sendMsg[culture]}</h2>
                  <p className="description">
                  {content.contact[culture]} <br></br>
                    <br></br>
                  </p>
                  </div>
                  <Form className={culture==='ar' ? "arForm" : ""} id="contact-form" role="form">
                    <label>{content.name[culture]}</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        aria-label="Your Name..."
                        autoComplete="name"
                        placeholder="..."
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>{content.email[culture]}</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        aria-label="Email Here..."
                        autoComplete="email"
                        placeholder="..."
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>{content.phone[culture]}</label>
                    <InputGroup
                      className={numberFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons tech_mobile"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        autoComplete="number"
                        placeholder="..."
                        type="text"
                        onFocus={() => setNumberFocus(true)}
                        onBlur={() => setNumberFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label>{content.msg[culture]}</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                      ></Input>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round"
                        style={{backgroundColor: "#A87A2B"}}
                        defaultValue="Contact Us"
                        type="submit"
                        disabled
                      >
                        {content.contactUs[culture]}
                      </Button>
                    </div>
                  </Form>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div className="info info-horizontal mt-5">
                    <div className="icon icon-info">
                      <i className="now-ui-icons location_pin" style={{color: "#A87A2B"}}></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">{content.findUs[culture]}</h4>
                      <p>
                        {content.address[culture]} <br></br>
                        V67M+F6R {content.tripoli[culture]}, <br></br>
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons tech_mobile" style={{color: "#A87A2B"}}></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">{content.call[culture]}</h4>
                      <p>
                        +218924618413 <br></br>
                        +218918933927
                      </p>
                    </div>
                  </div>
                  {/* <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="business_briefcase-24 now-ui-icons"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Legal Information</h4>
                      <p>
                        Creative Tim Ltd. <br></br>
                        VAT · EN2341241 <br></br>
                        IBAN · EN8732ENGB2300099123 <br></br>
                        Bank · Great Britain Bank
                      </p>
                    </div>
                  </div> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Container>
          {/* <MapWrapper /> */}
          <iframe className="big-map" id="contactUs2Map" src="https://www.google.com/maps/d/u/0/embed?mid=1FQ29c85fJ4RxreIgfD4XmLIxWKD_wnZR&ehbc=2E312F"></iframe>
          </Container>        
          <Footer />
      </div>
    </>
  );
}

export default ContactUs;
