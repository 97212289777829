import React from "react";

// reactstrap components
import { Card, Container, Row, Col, Button } from "reactstrap";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & contents 
import getCulture from "../../assets/utils/getCulture";
import contents from "./contents";

// core components

function Features() {

  //  Changing Language
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  return (
    <>
      <div className="cd-section" id="features">
        <div className="features-5">
          <Container>
            <Row>
              <Col md="5">
                <h2 className="title aboutUsT">{contents.aboutUsTitle[culture]}</h2>
                <div className="info">
                  <div className="description">
                    <span>
                      <h4 className="info-title">{contents.aboutUsDiscription[culture]}</h4>
                    </span>
                  </div>
                </div>
                <div className="info">
                  <Button className="aboutUsButton" onClick={() => window.location.assign(`/#/${culture}/about-us`)} >{contents.more[culture]}</Button>
                </div>
              </Col>
              <Col md="7">
                <div className="tablet-container ipad-full">
                  <img
                    alt="..."
                    src={require("assets/img/ipad-about.png").default}
                  ></img>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Features;
