export default {

    IT: {
        ar: "للحلول البرمجية",
        en: "IT & Software Solutions"
    },
    english: {
        ar: "الإنجليزية",
        en: "English"
    },
    arabic: {
        ar: "العربية",
        en: "Arabic"
    },
    lang: {
        ar: "اللغة",
        en: "Language"
    },
    contact: {
        ar: "تواصل معنا",
        en: "Contact Us"
    },
    about: {
        ar: "عنَّا",
        en: "About Us"
    }

}