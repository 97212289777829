import React from "react";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & contents 
import getCulture from "../../assets/utils/getCulture";
import contents from "./contents";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function Features() {

   //  Changing Language
   let rout = useRouteMatch()
   let culture = getCulture(rout.url);

  return (
    <>
      <div className="cd-section" id="features">
        <div
          className="features-2 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/GGW/people.jpg").default + ")",
          }}
        >
          <Container fluid>
            <Row>
              <Col className="mr-auto ml-auto" md="8">
                <h1 className="title servicesT">{contents.services[culture]}</h1>
                {/* <h4 className="description">{contents.servicesSub[culture]}</h4> */}
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info icon-circle">
                    <i className="now-ui-icons tech_laptop gold"></i>
                  </div>
                  <h4 className="info-title">{contents.software[culture]}</h4>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger icon-circle">
                    <i className="now-ui-icons business_globe gold"></i>
                  </div>
                  <h4 className="info-title">{contents.network[culture]}</h4>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-warning icon-circle">
                    <i className="now-ui-icons tech_mobile gold"></i>
                  </div>
                  <h4 className="info-title">{contents.mobileApp[culture]}</h4>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-warning icon-circle">
                    <i className="now-ui-icons users_single-02 gold"></i>
                  </div>
                  <h4 className="info-title">{contents.techCons[culture]}</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Features;
