import React from "react";

import {
    useRouteMatch,
  } from "react-router-dom";
  
  // get culture & contents 
  import getCulture from "../../assets/utils/getCulture";
  import contents from "./contents";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// core components

function FreeDemo() {

     //  Changing Language
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const [emailFocus1, setEmailFocus1] = React.useState(false);

  return (
    <>
      <div className="section section-free-demo">
        <Container>
          <Row>
            <Col lg="7" md="12">
              <div className={`section-descriptio ${culture==='ar'?'arForm':''}`}>
                <h2 className="title">{contents.demo[culture]}</h2>
                <h5 className="description">
                  {contents.demoD[culture]}
                </h5>
                <div className="github-buttons">
                <Card className="card-plain card-form-horizontal">
                    <CardBody>
                      <Form action="" method="">
                        <Row>
                          <Col sm="8">
                            <InputGroup
                              className={emailFocus1 ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons ui-1_email-85"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Your Email..."
                                type="text"
                                onFocus={() => setEmailFocus1(true)}
                                onBlur={() => setEmailFocus1(false)}
                                disabled
                              ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="4">
                            <Button
                              block
                              className="btn-round"
                              type="button"
                              disabled
                            >
                              {contents.demoTry[culture]}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                  {/* <Button
                    className="btn-round"
                    href="https://github.com/creativetimofficial/now-ui-kit-react?ref=creativetim"
                    target="_blank"
                  >
                    {contents.demoTry[culture]}
                  </Button> */}
                </div>
                <div className="clearfix"></div>
              </div>
            </Col>
            <Col lg="5" md="12">
              <div className="github-background-container logo">
                <img src={require('../../assets/img/GGW/logo.png').default}></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FreeDemo;
