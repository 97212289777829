export default
{
        alignt: {
            ar: "right",
            en: "left",
        },
		sendMsg: {
            ar: "تواصل معنا",
            en: "Send us a message"
        },
        contact: {
            ar: "يمكنك الاتصال بنا بخصوص أي شيء يتعلق بمنتجاتنا. سنتواصل معك في أقرب وقت ممكن.",
            en: "You can contact us with anything related to our Products. We'll get in touch with you as soon as possible."
        },
        findUs: {
            ar: "موقعنا",
            en: "Find us at the office"
        },
        call: {
            ar: "إتصل بنا",
            en: "Give us a ring"
        },
        name: {
            ar: "الإسم",
            en: "Your name"
        },
        email: {
            ar: "البريد الإلكتروني",
            en: "Email address"
        },
        phone: {
            ar: "الهاتف",
            en: "Phone"
        },
        contactUs: {
            ar: "تواصل معنا",
            en: "Contact Us"
        },
        msg: {
            ar: "الرسالة",
            en: "Your message"
        },
        ahmed: {
            ar: "أحمد المجعي",
            en: "Ahmed El-Mejai"
        },
        address: {
            ar: "طريق زناتة الجديدة بالقرب من جسر السريع",
            en: "Tariq Zanatah"
        },
        tripoli: {
            ar: "طرابلس",
            en: "Tripoli"
        },
        info: {
            ar: "تحتاج للمزيد من المعلومات ؟",
            en: "You need more information?"
        },
        sendUs: {
            ar: "أرسل إلينا",
            en: "Get in Touch"
        },
        lastName: {
            ar: "اللقب",
            en: "Last name"
        },
        firstName: {
            ar: "الإسم",
            en: "First name"
        },
        send: {
            ar: "إرسال",
            en: "Send message"
        },
        email: {
            ar: "البريد الإلكتروني",
            en: "Email"
        },



	}