/*eslint-disable*/
import React from "react";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & content 
import getCulture from "../../../assets/utils/getCulture";
import content from "./content";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";

// core components

function Projects() {

   //  Changing Language
   let rout = useRouteMatch()
   let culture = getCulture(rout.url);

   // setPills
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <div className="cd-section" id="projects">
        <div className="projects-4">
          <Container fluid>
            <Row>
              <Col className="mr-auto ml-auto text-center" md="8">
                <h3 className="category text-muted" style={{marginBottom: '-1em'}}>{content.ourWork[culture]}</h3>
                <h2 className="title">{content.projects[culture]}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="px-0" md="6">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/GGW/LZ.jpg").default +
                      ")"
                  }}
                >
                  <CardHeader className={culture==='ar' ? 'projectTit' : 'projectTiten'}>
                  <h3>{content.LZtitle[culture]}</h3>
                  <h4 className="projSubT gold">{content.LZsubtitle[culture]}</h4>
                  <p>{content.LZdiscription[culture]}</p>
                  </CardHeader>
                </Card>
                </Col>
                <Col className="px-0" md='4'>
                <Card className="card-fashion">
                <CardHeader className={culture==='ar' ? 'projectTitB' : 'projectTitBen'} >
                  <h3>{content.BCtitle[culture]}</h3>
                  <h4 className="projSubTB gold">{content.BCsubtitle[culture]}</h4>
                  <p>{content.BCdiscription[culture]}</p>
                  </CardHeader>
                  </Card>
              </Col>
              <Col className="px-0" md="2">
                  <Card
                    className="card-fashion card-background"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/GGW/BC.jpg").default +
                        ")",
                    }}
                  ></Card>
              </Col>
            </Row>
            <Row>
              <Col className="px-0" md="2">
                  <Card
                    className="card-fashion card-background"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/GGW/vote2.jpg").default +
                        ")",
                    }}
                  ></Card>
              </Col>
              <Col className="px-0" md="4">
              <Card className="card-fashion arrow-left">
              <CardHeader className={culture==='ar' ? 'projectTitB' : 'projectTitBen'} >
                  <h3>{content.Electiontitle[culture]}</h3>
                  <h4 className="projSubTB gold">{content.Electionsubtitle[culture]}</h4>
                  <p>{content.Electiondiscription[culture]}</p>
                  </CardHeader>
                  </Card>
                  </Col>
                  <Col className="px-0" md="6">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/GGW/tab.jpg").default +
                      ")",
                  }}
                >
                <CardHeader className={culture==='ar' ? 'projectTit' : 'projectTiten'} >
                    <h3>{content.OStitle[culture]}</h3>
                    <p>{content.OSdiscription[culture]}</p>
                    </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Projects;
