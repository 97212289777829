export default
{
        alignt: {
            ar: "right",
            en: "left",
        },
		aboutUs: {
            ar: "عنّا",
            en: "About Us"
        },
        discription: {
            ar: ".قابل فريق العمل وراء هذا المشروع واكتشف المزيد حول كيفية عملنا",
            en: "Meet the amazing team behind this project and find out more about how we work."
        },
        title: {
            ar: "ماهي شركة المنفذ الذهبي؟",
            en: "what is Golden Gateway?"
        },
        subTitle: {
            ar: "وماهي الخدمات التي تقدمها؟",
            en: "And what are the offered services?"
        },
        ggwDiscription: {
            ar: "قيت واي هي شركة مختصة بتقديم حلول تقنية فريدة تلبي احتياجات المستخدم بجودة وكفاءة تحاكي المعايير العالمية، تأسست منذ 2010م، بكادر وظيفي لأكثر من 20 موظف من أصحاب المؤهلات العليا وذوي خبرات بشركات عالمية. المقر الرئيسي زناتة، طرابلس",
            en: "Gateway is a company specialized in technical ideas and software solutions that meet customer needs with high quality and efficiency. Headquarters in Zanatah, Tripoli, Libya."
        },
        faq: {
            ar: "أسئلة شائعة",
            en: "FAQ"
        },
        Q1: {
            ar: "كيف يمكن طلب الخدمات منكم؟",
            en: "How can the services be requested?"
        },
        Q2: {
            ar: "ماهي الانظمة اللتي تقدمها الشركة؟",
            en: "What are the systems provided by the company?"
        },
        Q3: {
            ar: "هل يمكن توسيع النظام إذا لزم الأمر في المستقبل؟",
            en: "Can I expand the system if required in the future?"
        },
        A1: {
            ar: "يمكن التواصل معنا عبـر الهاتف او البـريد الالكتـروني او عبـر اي منصة من منصات التواصل الاجتماعي الخاصة بنا، وبعد عملية التواصل يتم تحديد موعد ويتم مناقشة رغبات العميل بالكامل",
            en: "You can contact us by phone, e-mail, or through any of our social media platforms, and after the communication process, an appointment is set and the client's wishes are fully discussed."
        },
        A2: {
            ar: "لدينا بالفعل مجموعة من الانظمة الادارية اللتي نفتخر بتقديمها نظام ادارة التعلم، نظام تخطيط موارد المؤسسة، نظام شؤون الموظفين، نظام الحضور والانصراف، نظام التدريب، نظام المرتبات، نظام ادارة المحاسبة، نظام المخازن والشتريات، نظام الارشفة الالكترونية، نظام المراسلات الادارية الصادر والوارد، نظام الخدمات الذاتية، نظام المخزن الذكي، نظام ادارة المشروعات … وغيرها من الانظمة الاخرى",
            en: "We already have a set of administrative systems that we are proud to provide, learning management system, enterprise resource planning system, personnel system, attendance system, training system, payroll system, accounting management system, warehouse and procurement system, electronic archiving system, incoming and outgoing administrative correspondence system, Self-service System, smart warehouse system, project management system... and other systemsز"
        },
        A3: {
            ar: "نحن نطور البرامج مع الأخذ في الاعتبار أن المنتج يتطور دائمًا. نحن نصمم النظام باستخدام طبقات منفصلة لكل مشكلة مما يجعل الاقتران سائباً. قد ترغب في تحويل ملف تطبيق مستند إلى سطح المكتب في تطبيق ويب. نظرًا لأن منطق الأعمال والوصول إلى البيانات وخدمات البنية التحتية تظل في طبقات منفصلة ، فلن تحتاج إلى إعادة كتابة كاملة ، ستقوم واجهة مستخدم الويب في طبقة العرض التقديمي بتنفيذ العمل. وتطوير الشيء نفسه ينطبق على قواعد البيانات وكذلك",
            en: "We develop software keeping in mind that a product always evolves. We design the system using separate layers for each concern making a loose coupling. You may want to convert adesktop-based application into a web application. As the business logic, data access, and infrastructure services remain in separate layers, you will not need a complete rewrite, developing aweb UI in the presentation layer will do the work. The same goes for databases and so on"
        },
        A21: {
            ar: "لدينا بالفعل مجموعة من الانظمة الادارية اللتي نفتخر بتقديمها نظام ادارة التعلم، نظام تخطيط موارد المؤسسة، نظام شؤون الموظفين، نظام الحضور والانصراف",
            en: "We already have a set of administrative systems that we are proud to provide, learning management system, enterprise resource planning system, personnel system, attendance system"
        },
        A22: {
            ar: "، نظام التدريب، نظام المرتبات، نظام ادارة المحاسبة، نظام المخازن والشتريات، نظام الارشفة الالكترونية، نظام المراسلات الادارية الصادر والوارد، نظام الخدمات الذاتية، نظام المخزن الذكي، نظام ادارة المشروعات … وغيرها من الانظمة الاخرى",
            en: ", training system, payroll system, accounting management system, warehouse and procurement system, electronic archiving system, incoming and outgoing administrative correspondence system, Self-service System, smart warehouse system, project management system... and other systems"
        },
        A31: {
            ar: "نحن نطور البرامج مع الأخذ في الاعتبار أن المنتج يتطور دائمًا. نحن نصمم النظام باستخدام طبقات منفصلة لكل مشكلة مما يجعل الاقتران سائباً. قد ترغب في تحويل ملف تطبيق مستند إلى سطح المكتب في تطبيق ويب.",
            en: "We develop software keeping in mind that a product always evolves. We design the system using separate layers for each concern making a loose coupling. You may want to convert adesktop-based application into a web application."
        },
        A32: {
            ar: " نظرًا لأن منطق الأعمال والوصول إلى البيانات وخدمات البنية التحتية تظل في طبقات منفصلة ، فلن تحتاج إلى إعادة كتابة كاملة ، ستقوم واجهة مستخدم الويب في طبقة العرض التقديمي بتنفيذ العمل. وتطوير الشيء نفسه ينطبق على قواعد البيانات وكذلك",
            en: " As the business logic, data access, and infrastructure services remain in separate layers, you will not need a complete rewrite, developing aweb UI in the presentation layer will do the work. The same goes for databases and so on"
        },
        more: {
            ar: " إظهار المزيد",
            en: "Show more"
        },
        less: {
            ar: "إظهار أقل",
            en: "Show less"
        },
        title2: {
            ar: "آلية العمل",
            en: "Work Process"
        },
        work: {
            ar: "نحن نؤمن بالابتكار والتحسين المستمر،تؤكد منهجية اجايل على التواصل والتعاون، يتميز فريقنا بالمرونة للتكيف مع حقائق الأعمال الناشئة، وهذا يساعدنا على إدارة وتحقيق أهداف مشاريع عملائنا في جميع أنحاء العالم",
            en: "We believe in constant innovation and improvements. The Agile methodology emphasizes communication and collaboration. Our team is flexible to adapt to the emerging business realities. It helps us to successfully manage and accomplish the goals of our customer’s projects worldwide."
        },
        team: {
            ar: "فريق عمل مجد",
            en: "Hardworking Team"
        },
        paltform: {
            ar: "منصة إنتاجية",
            en: "A Productivity Platform"
        },
        sync: {
            ar: "دائما في المزامنة",
            en: "Always In Sync"
        },
        ourWork: {
            ar: "أعمالنا",
            en: "Our Work"
        },
        projects: {
            ar: "إليك بعض من مشاريعنا",
            en: "Some of Our Awesome Projects"
        },
        LZtitle: {
            ar: "نطاق التعلم",
            en: "LearnZone"
        },
        LZsubtitle: {
            ar: "نظام إداري تعليمي",
            en: "Learning Management System"
        },
        LZdiscription: {
            ar: "تم تطوير هذا النظام ليكون نظام إدارة  برامج التعلم يرتكز على شبكة الانترنت يستخدم في إدارة وتوثيق و متابعة وتقييم التدريب والتعليم المستمر وتقديم المحتوى التعليمي للمستفيدين. ت ساهم فريق العمل في تدشين وافتتاح مركز تدريب الوكالة المغاربية لمناشط الشباب للاتحاد المغاربي وذلك عن طريق توفير منصة تعلم عن بعد للمركز",
            en: "LearnZone was developed to be a web-based learning management system used to manage, document, monitor and evaluate training and continuing education and deliver educational content to beneficiaries. The team contributed to the launch and opening of the training center of the Maghreb Agency for Youth Activities of the Maghreb Union, by providing a virtual learning platform for the center."
        },
        BCtitle: {
            ar: "نظام تصويت إلكتروني لامركزي",
            en: "Decentralized E-voting System"
        },
        BCsubtitle: {
            ar: "بإستخدام تقنية البلوكتشين",
            en: "Using Blockchain Technology"
        },
        BCdiscription: {
            ar: "منصة تصويت لامركزي تستخدم تقنية البلوكتشاين لتفعيل نظام انتخاب، استفتاء، تصويت، أو استبيان يضمن السرية والشفافية والمصداقية. تم تطوير المنصة ضمن حدث هاكاثون التكنولوجي المنظم من قبل مؤسسة خبراء فرنسا والممول من قبل السفارة الفرنسية. تحصلت المنصة على جائزة أكثر حل إبداعي ضمن الحدث.",
            en: "A decentralized voting platform that uses Blockchain technology to activate an election system, a referendum, a vote, or a questionnaire that guarantees confidentiality, transparency and credibility. The platform has been developed within the TechState Hackathon that was organized by Expertise France and funded by the French Embassy. The platform won the award for the most creative solution within the event."
        },
        OStitle: {
            ar: "نظام تشغيل في الوقت الفعلي",
            en: "Real Time Operating System"
        },
        OSdiscription: {
            ar: "تطوير نظام تشغيل متعدد المهام للمعالجات الرقمية يلبي احتياجات التطبيقات الحرجة والتي تستدعي سرعة استجابة. يستهدف هذا النظام عمليات الأتمتة بالجهات الصناعية لتعزيز كفاءة الإنتاج. كما يستخدم كنظام تحكم بالروبوتات الذكية.",
            en: "Developing a multitasking operating system for digital processors that meets the needs of critical applications that require rapid response. This system targets the automation processes of industrial facilities to enhance production efficiency. It is also used as a control system for smart robots."
        },
        Electiontitle: {
            ar: "بناء الموارد في الإنتخابات",
            en: "Building Resources In Democracy Governance and Elections"
        },
        Electionsubtitle: {
            ar: "BRIDGE",
            en: "BRIDGE"
        },
        Electiondiscription: {
            ar: "هو نظام تصويت الكتروني يهدف إلى تمكين عملية الانتقال السلمي للسلطة وإلى تعزيز الموارد التقنية الداعمة للديمقراطية والحوكمة والانتخابات. فريق العمل على تواصل مع برنامج الأمم المتحدة الإنمائي بليبيا وذلك لغرض تطوير نموذج أولي يمكن محاكاته.",
            en: "It is an electronic voting system that aims to enable the peaceful transfer of power and to enhance technical resources in support of democracy, governance and elections. The team is in contact with the United Nations Development Program in Libya for the purpose of developing a prototype that can be simulated."
        },
        ERPtitle: {
            ar: "نظام تخطيط موارد المؤسسة",
            en: "Enterprise Resource Planning"
        },
        ERPdiscription: {
            ar: "هو برنامج إدارة عمليات متكامل يمكن المؤسسة من إدارة كل من الأقسام المالية، أنشطة سلسلة التوريد، إعداد التقارير، التصنيع، الموارد البشرية. رغم أن معظم المؤسسات تمتلك أنظمة تمويل وتشغيل إلا أن هذه الأنظمة لا يمكنها تجاوز العمليات اليومية ولا توفر أي مقاييس لنمو المؤسسة. لما قد تتبنى نظام تخطيط الموارد لمؤسستك سهولة الاستخدام. إن آلية عمل نظام تخطيط موارد المؤسسات  بالإضافة إلى واجهة مستخدم مبسطة تمكن من زيادة كفاءة فرق العمل. كل أنظمتك واجراءاتك في مكان واحد. يوفر النظام برنامج متكامل يحوي جميع الأنظمة والإجراءات الإدارية للمؤسسة مما يساهم في رفع مستوى التعاون والإنجاز وسرعة  الوصول. عمليات أتمتة وتسليس الإجراءات الروتينية توفر الوقت والجهد كما يمكن النظام من تتبع الإجراءات بيسر",
            en: "It is an integrated operations management software that enables the organization to manage each of the financial departments, supply chain activities, reporting, manufacturing, and human resources. Although most organizations have financing and operating systems, these systems cannot bypass the day-to-day operations and do not provide any measures of the organization's growth. Why your enterprise resource planning system might be easy to use. The working mechanism of the ERP system in addition to a simplified user interface enables to increase the efficiency of the work teams. All your systems and procedures in one place. The system provides an integrated program that contains all the administrative systems and procedures for the institution, which contributes to raising the level of cooperation, achievement and speed of access. Automating and streamlining routine procedures saves time and effort, and the system can easily track procedures"
        },
        ERPdefT: {
            ar: "ماهو نظام تخطيط الموارد ؟",
            en: "What is ERP ?"
        },
        ERPdef: {
            ar: "هو برنامج إدارة عمليات متكامل يمكن المؤسسة من إدارة كل من الأقسام المالية، أنشطة سلسلة التوريد، إعداد التقارير، التصنيع، الموارد البشرية. رغم أن معظم المؤسسات تمتلك أنظمة تمويل وتشغيل إلا أن هذه الأنظمة لا يمكنها تجاوز العمليات اليومية ولا توفر أي مقاييس لنمو المؤسسة. لما قد تتبنى نظام تخطيط الموارد لمؤسستك سهولة الاستخدام.",
            en: "It is an integrated operations management software that enables the organization to manage each of the financial departments, supply chain activities, reporting, manufacturing, and human resources. Although most organizations have financing and operating systems, these systems cannot bypass the day-to-day operations and do not provide any measures of the organization's growth. Why your enterprise resource planning system might be easy to use."
        },
        ERPwpT: {
            ar: "آلية عمل النظام",
            en: "Working Mechanism"
        },
        ERPwp: {
            ar: "إن آلية عمل نظام تخطيط موارد المؤسسات  بالإضافة إلى واجهة مستخدم مبسطة تمكن من زيادة كفاءة فرق العمل. كل أنظمتك واجراءاتك في مكان واحد. يوفر النظام برنامج متكامل يحوي جميع الأنظمة والإجراءات الإدارية للمؤسسة مما يساهم في رفع مستوى التعاون والإنجاز وسرعة  الوصول. عمليات أتمتة وتسليس الإجراءات الروتينية توفر الوقت والجهد كما يمكن النظام من تتبع الإجراءات بيسر.",
            en: "The working mechanism of the ERP system in addition to a simplified user interface enables to increase the efficiency of the work teams. All your systems and procedures in one place. The system provides an integrated program that contains all the administrative systems and procedures for the institution, which contributes to raising the level of cooperation, achievement and speed of access. Automating and streamlining routine procedures saves time and effort, and the system can easily track procedures."
        },
        teamTitle: {
            ar: "فريق العمل",
            en: "The Executive Team"
        },
        teamBrief: {
            ar: "تعرف على الأشخاص الذين يقفون وراء شركة العبارة",
            en: "Meet the people behind Golden Gateway co."
        },
        ahmedM: {
            ar: "أحمد المجعي",
            en: "Ahmed El-Mejai"
        },
        ahmeMP:
        {
            ar: "مدير تنفيذي",
            en: "CEO"
        },
        ahmedA: {
            ar: "أحمد العالم",
            en: "Ahmed Al-Alem"
        },
        ahmedAP:
        {
            ar: "المدير التقنـي",
            en: "Technical Director"
        },
        ziad: {
            ar: "زياد سالم",
            en: "Ziad Salem"
        },
        ziadP:
        {
            ar: "إدارة المشاريع",
            en: "Project Management"
        },
        abdrahman: {
            ar: "عبد الرحمن إبراهيم",
            en: "Abd Alrahman Ibrahim"
        },
        abdrahmanP:
        {
            ar: "مدير تطوير الأعمال",
            en: "Buisness Development manager"
        },
	}