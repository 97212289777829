import React from "react";

import {
  useRouteMatch,
} from "react-router-dom";

// get culture & content 
import getCulture from "../../../assets/utils/getCulture";
import content from "./content";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Teams() {
  
   //  Changing Language
   let rout = useRouteMatch()
   let culture = getCulture(rout.url);

  return (
    <>
      <div className="cd-section" id="teams">
        <div className="team-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{content.teamTitle[culture]}</h2>
                <h4 className="description">
                {content.teamBrief[culture]}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            height={200}
                            src={require("assets/img/user.jpeg").default}
                          ></img>
                        </a>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">{content.ahmedM[culture]}</CardTitle>
                        <h4 className="category teamMem">{content.ahmeMP[culture]}</h4>
                        {/* <CardFooter>
                          <Button
                            className="btn-icon btn-neutral"
                            color="twitter"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-twitter"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="facebook"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-facebook-square"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="google"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-google"></i>
                          </Button>
                        </CardFooter> */}
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            height={200}
                            src={require("assets/img/user.jpeg").default}
                          ></img>
                        </a>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">{content.ahmedA[culture]}</CardTitle>
                        <h4 className="category teamMem">{content.ahmedAP[culture]}</h4>
                        {/* <CardFooter>
                          <Button
                            className="btn-icon btn-neutral"
                            color="linkedin"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="facebook"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-facebook-square"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="dribbble"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-dribbble"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="google"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-google"></i>
                          </Button>
                        </CardFooter> */}
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            height={200}
                            src={require("assets/img/user.jpeg").default}
                          ></img>
                        </a>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">{content.ziad[culture]}</CardTitle>
                        <h4 className="category teamMem">{content.ziadP[culture]}</h4>
                        {/* <CardFooter>
                          <Button
                            className="btn-icon btn-neutral"
                            color="linkedin"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="facebook"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-facebook-square"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="google"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-google"></i>
                          </Button>
                        </CardFooter> */}
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            height={200}
                            src={require("assets/img/user.jpeg").default}
                          ></img>
                        </a>
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">{content.abdrahman[culture]}</CardTitle>
                        <h4 className={`category ${culture === 'en'? 'teamMemA' : 'teamMem'}`}>{content.abdrahmanP[culture]}</h4>
                        {/* <CardFooter>
                          <Button
                            className="btn-icon btn-neutral"
                            color="youtube"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-youtube"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="twitter"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-twitter"></i>
                          </Button>
                          <Button
                            className="btn-icon btn-neutral"
                            color="instagram"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-instagram"></i>
                          </Button>
                        </CardFooter> */}
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Teams;